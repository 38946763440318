export default {
  route: {
    Added: '新增/修改',
    ViewoOrder: '查看',
    Detail: '详情',
    CarrierSettlement: '承运商结算',
    ProjectManagement: '费用项目管理',
    LogisticscostManagement: '物流费用管理',
    LogisticsSettlementManagement: '物流结算管理',
    SupplierSettlement: '供应商结算',
    StatementManagement: '对账单管理',
    StatementManagementDetail: '对账单',
    PurchaseSettlement: '采购结算管理',
    FinancialPayment: '财务付款管理'

  },
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出登录',
    profile: '个人中心',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    title: 'FIN系统登录',
    logIn: '登录',
    username: '账号',
    password: '密码',
    verificationCode: '验证码',
    LoginFailed: '登陆失效',
    Relogin: '重新登陆',
    HaveBeenLoggedOut: '你已经被强制登出,你可以关闭弹窗停留在该页面或者重新登录'
  },
  footer: {
    help: '帮助',
    privacy: '隐私',
    clause: '条款'
  },
  page: {
    capitalLetterAndNumber: '请输入两位字母或数字',
    plzChooseDateTime: '请选择日期时间',
    IncorrectFormat: '格式有误',
    IncorrectFormatVendorSimple: '格式有误，请输入两位字母或数字',
    positioning: '产品定位',
    targetNumberOfDays: '下单目标天数',
    /** 公用 */
    address: '地址',
    requestTimeOut: '请求已超时,请重试.',
    homepage: '首页',
    Details: '详情',
    search: '搜索',
    reset: '重置',
    expand: '展开',
    hide: '隐藏',
    create: '新建',
    export: '导出',
    startTimeEndTime: '开始时间—结束时间',
    No: '序号',
    gender: '性别',
    man: '男',
    woman: '女',
    inputPlaceholder: '请输入',
    selectPlaceholder: '请选择',
    enabled: '启用',
    disable: '禁用',
    operate: '操作',
    view: '查看',
    modify: '修改',
    invalid: '作废',
    createDate: '创建日期',
    createTime: '创建时间',
    receiveTime: '领取时间',
    finishTime: '完成时间',
    handleTime: '操作时间',
    createByName: '创建人',
    warehouse: '仓库',
    Linkman: '联系人',
    sure: '确定',
    delete: '删除',
    add: '新增',
    import: '导入',
    deliveryImport: '交期导入',
    templateDownload: '模板下载',
    tray: '托盘',
    storageArea: '储区',
    expectedNum: '预期数量',
    expectedBoxNum: '预期箱数',
    close: '关闭',
    Specifications: '规格型号',
    total: '合计',
    alreadySel: '已选择',
    rowS: '项',
    batchRemove: '批量删除',
    batchCreate: '批量新增',
    others: '其他',
    cancel: '关闭',
    submit: '提交',
    /** 个人中心 */
    BasicInformation: '基本信息',
    required: '必填',
    Newpurchaseplan: '新增采购计划',
    Style: '款式',
    Color: '颜色',
    Size: '尺码',
    // SKU其他信息
    ModifySKUOtherInfo: '修改SKU其他信息',
    firstLevelSuppliers: '一级供应商',
    secondLevelSuppliers: '二级供应商',
    /** 个人中心 */
    InformationManagement: '信息管理',
    username: '用户名',
    Email: '邮箱',
    Avatar: '头像',
    PasswordManagement: '密码管理',
    Password: '密码',
    Newpassword: '新密码',
    ConfirmPassword: '确认密码',
    ResetPassword: '重置密码',
    Character: '角色',
    Staff: '员工',
    status: '状态',
    image: '图片',
    supplier: '供应商',
    NoPictureInformation: '暂无图片信息',
    SKUImages: 'SKU 图片',
    EditImages: '编辑图片',
    AddImages: '添加图片',
    skuMainPic: '主图',
    auxiliary: '副图',
    model: '模特图',
    SureToDeleteDetail: '确定要删除明细吗?',
    Prompt: '提示',
    UsernameAlreadyExists: '用户名已经存在，请重新输入',
    Password6: '密码不能少于6个字符',
    UsernameIsInvalid: '用户名不合法',
    PlzInputPassword: '请输入密码',
    StartWith1Letters: '以2位字母开头1位数字结尾 如:a1',
    StartWith2Letters: '以2位字母开头1位数字结尾 如:aa1',
    Enter2Letters: '请输入2个字母（A-Z）字符，如AA',
    categoryOneRequired: '一级类别必选',
    categoryTwoRequired: '二级类别必填',
    ColorRequired: '颜色必填',
    ColorKeyRequired: '颜色key必填',
    StatusRequired: '状态必选',
    categoryRequired: '类别必选',
    SizeRequired: 'Size必填',
    SizeKeyRequired: 'SizeKey必填',
    digits3: '3位数字,不能以0开头',
    digits34: '3-4位数字,不能以0开头',
    digits16: '请输入大于0的1-6位整数',
    digits16port4: '请输入大于0,1-6位且保留4位小数',
    IncompleteBasicInformation: '基础信息不全无法保存,请先补全后重试',
    addMaterial: '先给该style添加材料组件',
    GeneratedFromOther: 'sku由其他数据生成',
    typeBy: '所属分类',
    LabelReplenishPrint: '标签打印',
    phoneNumber: '手机号',
    contactNumber: '联系电话',
    ConfirmImport: '确认导入',
    vendorSimpleRequired: '供应商简称必填',
    SureDelete: '确定删除吗？'
  },
  title: {
    // 文件导出
    ExportTaskName: '导出任务名称',
    ExportCompleTime: '导出完成时间',
    AbnormalInformation: '异常信息',
    // 审批翻译
    Approvalnumber: '审批单号',
    Associatednumber: '关联单号',
    Approvaltype: '审批类型',
    Purchaseplan: '采购计划',
    Operationapproval: '运营审批中',

    onePrintDetail: '请至少选择一条打印数据',
    printType: '打印类型',
    Permission: '权限',
    back: '返回',
    Save: '保存',
    Edit: '编辑',
    Replace: '替换',
    BasicInformation: '基本信息',
    submit: '提交',
    cancel: '取消',
    confirm: '确认',
    ExportDetail: '导出明细',
    // 一级类别表头字段翻译
    OneCategory: '一级类别',
    OneCategoryKey: '一级类别Key',
    // 二级类别翻译
    TwoCategory: '二级类别',
    TwoCategoryKey: '二级类别Key',
    // 三级类别翻译
    ThreeCategory: '三级类别',
    ThreeCategoryKey: '三级类别Key',
    // 品牌表头翻译
    BrandName: '品牌名称',
    BrandKey: '品牌Key',
    // 款式翻译
    Style: '款式',
    Brand: '品牌',
    Years: '年份',
    Season: '季节',
    Spring: '春季',
    Summer: '夏季',
    Autumn: '秋季',
    Winter: '冬季',
    All: '全部',
    AllSeason: '四季款',
    SectionNumber: '款号',
    // SKU材料组件管理
    Components: '组件',
    OtherInformation: 'SKU其他信息',
    // 平台SKU信息
    Color: '颜色',
    Size: '尺码',
    ThisSelection: '本次选中',
    Strip: '条SKU',
    SKUTitle: '修改SKU颜色',
    SKUStatus: '修改SKU状态',
    PrintField: '打印字段',
    PrintPaper: '打印纸张',
    ReplaceSKU: '替换SKU',
    AssignUPC: '是否分配UPC',
    Category: '类别',
    ReplaceTime: '替换次数',
    Yes: '是',
    No: '否',
    StyleInformation: 'Style信息',
    SKUInfo: 'SKU信息',
    SurfaceMaterial: '面材料',
    InMaterial: '里材料',
    BottomMaterial: '底材料',
    CushionMaterial: '垫材料',
    OtherInfo: '其他信息',
    SkuPicture: 'SKU 图片',
    BatchEditColor: '批量修改颜色',
    BatchEditStatus: '批量修改状态',
    print: '打印',
    Site: '站点',
    Platform: '平台',
    // 盒子翻译
    boxLabel: '盒子',
    BoxName: '盒子名称',
    BoxHeight: '盒子高度',
    BoxWidth: '盒子宽度',
    BoxLength: '盒子长度',
    BoxWeight: '盒子重量',
    boxVolume: '盒子立方',
    // 箱规翻译
    CartonName: '箱子名称',
    CartonNumber: '箱子双数',
    CartonHeight: '箱子高度',
    CartonWidth: '箱子宽度',
    cartonLength: '箱子长度',
    CartonWeight: '箱子重量',
    CartonVolume: '箱子立方',
    boxSize: '盒子尺寸',
    shoeTree: '楦型',
    // SKU管理
    SimplifySKU: '简化plat_sku',
    filterplatform: '勾选后生成的plat_sku会删除空格，_替换成-，超过30位前-部分会自动删除',
    leastsync: '请至少选择一条SKU同步',
    Synchronize: '同步SKU至站点',
    synchronizeFnskuUpc: '同步FNSKU/UPC',
    synchronousSource: '请选择同步来源平台/站点',
    pleaseSynchronous: '请选择同步编码',
    EditSku: '修改SKU属性',
    Choose: '已选择',
    StripNumber: '项',
    Unit: '箱双',
    BoxUnit: '箱子-(长*宽*高)',
    chooseAsku: '至少选择一条sku',
    modifySameStyle: '请选择相同style修改',
    // 材料信息翻译
    MaterialInformation: '材料信息',
    MaterialsName: '材料名称',
    materialsKey: '材料Key',
    // 供应商翻译
    vendorName: '供应名称',
    vendorFull: '供应商全称',
    vendorSimple: '供应商简称',
    vendorType: '供应商等级',
    province: '省份',
    city: '城市',
    Country: '国家',
    districtsAndCounties: '区/县',
    vendorCode: '供应商编号',
    registeredAddr: '供应商注册信息',
    detailedAddr: '供应商详细地址',
    salesReturnAddr: '退换货地址',
    payType: '结算方式',
    minimumNum: '最小采购数',
    cargoSrc: '境内货源地',
    taxRates: '开票税率',
    brcfilelist: '三证合一:',
    lfoafilelist: '账户资料:',
    pafilelist: '采购协议:',
    depositBank: '开户银行',
    depositName: '开户名称',
    bankAccount: '银行账号',
    taxIdentificationCode: '纳税人识别号',
    Established: '成立时间',
    fax: '传真',
    email: '邮箱地址',
    Wechat: '微信',
    qq: 'QQ',
    buyer: '采购员',
    accountPeriod: '账期',
    // 采购退货
    chageReturn: '确定转WMS退货出库单吗?',
    enterpriseSize: '企业规模',
    generalTaxpayer: '一般纳税人',
    smallScale: '小规模',
    addressInformation: '地址信息',
    contactInformation: '联系人信息',
    paymentInformation: '支付信息',
    contractingBody: '签约主体',
    bankName: '开户行名称',
    beneficiaryBankName: '收款银行名称',
    bankNumber: '行号',
    quotationCurrency: '报价币种',
    releasePairs: '可申请数量',
    applyPairs: '申请数量',
    lockPairs: '锁定'

  }
}
