import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
// import { asyncRoutes } from '@/router/index'
import { getMenus } from '@/api/user'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect', '/inner-dashboard', '/vendor/reconcile'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  NProgress.start()

  // 设置页面Title
  document.title = getPageTitle(to.meta.title)
  // 是否获取token
  const hasToken = getToken()
  // const hasWarehouseList = store.getters.warehouseList.length > 0
  // const hasWarehouse = store.getters.warehouse
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      const permissionRoutes = store.getters.permission_routes
      const { length, last = length - 1 } = permissionRoutes
      const hasMenu = permissionRoutes && permissionRoutes[last] && permissionRoutes[last].path === '*'
      if (hasMenu) {
        next()
      } else {
        try {
          await store.dispatch('user/getInfo')
          // if (!hasWarehouseList) {
          //   const { datas } = await queryWarehouseList()
          //   store.dispatch('app/setWarehouseList', datas)
          //   if (!hasWarehouse) store.dispatch('app/setWarehouse', datas[0].id)
          // }
          const { datas } = await getMenus()
          let finMenus = []
          const item = datas.find(item => item.meta.title === 'FIN-admin__menu')
          if (item) {
            finMenus = item.children
          }
          // console.log(finMenus)
          const accessRoutes = await store.dispatch('permission/createRoutes', finMenus)
          router.addRoutes(accessRoutes)
          next({ ...to, replace: true })
        } catch (error) {
          Message({
            message: error || 'Error',
            dangerouslyUseHTMLString: true,
            type: 'error',
            duration: 5 * 1000
          })
          await store.dispatch('user/resetToken')
          next(`/login?redirect=${to.path}${ObjectToURL(to.query)}`)
          NProgress.done()
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1 || to.path.includes('/iframe/')) {
      next()
    } else {
      next(`/login?redirect=${to.path}${ObjectToURL(to.query)}`)
      NProgress.done()
    }
  }
})

router.afterEach((to) => {
  // 动态获取route.meta.permissions
  store.dispatch('permission/updataPermissions', to.meta.permissions)
  NProgress.done()
})
function ObjectToURL(obj) {
  if (!Object.prototype.toString.call(obj) === '[object Object]') { return '' }
  const objKeyArray = Object.keys(obj).map(key => `${key}=${obj[key] || ''}`)
  // console.log(objKeyArray)
  return '&' + objKeyArray.join('&')
}
