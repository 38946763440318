/*
 * @Author: your name
 * @Date: 2021-12-22 14:42:58
 * @LastEditTime: 2021-12-22 16:52:45
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \fin\src\utils\router-map.js
 */
import Layout from '@/layout'
import NullRouterView from '@/layout/null-router-view'

const routerMap = {
  Layout,
  NullRouterView,
  // 承运商结算
  LogisticscostManagement: () => import('@/views/carrier-settlement/logisticscost-management/index'),
  LogisticscostRegister: () => import('@/views/carrier-settlement/logisticscost-management/register/index'),
  LogisticsSettlementManagement: () => import('@/views/carrier-settlement/logisticssettlement-management/index'),
  LogisticsSettlementReconcile: () => import('@/views/carrier-settlement/logisticssettlement-management/reconcile/index'),
  ProjectManagement: () => import('@/views/carrier-settlement/project-management/index'),
  FinancialPayment: () => import('@/views/supplier-settlement/financial-payment/index')
}

export default routerMap
