<template>
  <footer>
    <div class="footer-select">
      <router-link class="navigation" to="/">{{ $t('footer.help') }}</router-link>
      <router-link class="navigation" to="/">{{ $t('footer.privacy') }}</router-link>
      <router-link class="navigation" to="/">{{ $t('footer.clause') }}</router-link>
    </div>
    <div class="company-name">wangoon©弯弓信息科技有限公司</div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
footer {
  margin-top: 40px;
  color:#858687;
  font-size: 14px;

}
.footer-select {
  display: flex;
  justify-content: center;
  align-items: center;
  .navigation {
    padding: 10px;
  }
}
.company-name {
  text-align: center;
}
.pda {
  text-align: center;
}
@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
